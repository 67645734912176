import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import Layout from "../components/layout";
import BannerLanding from "../components/BannerLanding";
import picBooks from "../assets/images/books.jpg";
import logoVzc from "../assets/images/logo-vzc_square.png";
import pic08 from "../assets/images/pic08.jpg";
import pic09 from "../assets/images/pic09.jpg";
import pic10 from "../assets/images/pic10.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const VzcPage = (props) => (
  <Layout>
    <Helmet>
      <title>van Zuiden Communications | Mecosud B.V.</title>
      <meta name="description" content="Van Zuiden Communications" />
    </Helmet>

    <BannerLanding
      title="van Zuiden Communications"
      subTitle="Medische uitgeverij"
      image={logoVzc}
      styleClass="style-vzc"
      photo={picBooks}
    />

    <div id="main" className="alt" style={{ backgroundColor: "#0089bc" }}>
      <section id="one">
        <div className="inner">
          <p style={{ width: "80%" }}>
            Sinds 1991 zijn wij onder de naam Van Zuiden Communications actief
            als uitgeverij in de gezondheidszorg. Wij hebben veel ervaring
            opgedaan met het produceren van diverse product(series).
          </p>
        </div>
      </section>

      <section id="six" style={{ backgroundColor: "#006D96" }}>
        <div className="inner">
          <p style={{ width: "80%" }}>
            Op 24 april 2018 heeft Van Zuiden Communications B.V. de Medidact
            tak van het bedrijf aan Springer Healthcare verkocht (zie{" "}
            <a
              href="http://springerhealthcare.nl/over-ons/persbericht-springer-nature-neemt-medidact-onderdeel-van-van-zuiden-communications/"
              target="_blank"
              title="persbericht"
              rel="noopener noreferrer"
              aria-describedby="a11y-new-window-external-message"
            >
              persbericht
            </a>
            ). Mecosud B.V. gaat met een deel van de activiteiten van Van Zuiden
            Communications B.V. door.
          </p>
          <p style={{ width: "80%" }}>
            Onder de handelsnaam Van Zuiden Communications blijven wij actief
            met het uitgeven van zakboekjes, boeken, apps en educatieve
            projecten gericht op de gezondheidszorg.
          </p>
          <p>
            De webshop met boeken van is geheel vernieuwd en gewoon nog te
            bereiken via{" "}
            <a
              href="https://www.vanzuidencommunications.nl/?utm=mecosud.nl"
              target="_blank"
              rel="noopener norefer"
            >
              vanzuidencommunications.nl
            </a>
            .
          </p>
          <p>
            <a
              href="https://www.vanzuidencommunications.nl/?utm=mecosud.nl"
              target="_blank"
              rel="noopener norefer"
              className="button next"
            >
              Naar de webshop
            </a>
          </p>
        </div>
      </section>

      <section id="seven">
        <div className="inner">
          <h3>Wilt u met ons samenwerken?</h3>
          <p>We horen graag van u!</p>
        </div>
      </section>
    </div>
  </Layout>
);

export default VzcPage;
